<template>
  <div>
    <div v-if="(user.tags.length || user.interest_questions.length) && !surveyStarted" width="100%" class="pa-6">
      <v-img width="30%" class="mx-auto" src="../../assets/survey1.png"></v-img>
        <v-btn color="primary" absolute right elevation="0" class="mb-5 mr-7" @click="surveyStarted = true"><v-icon left>mdi-cog</v-icon> Manage Interest</v-btn>

        <span class="text-h5 primaryDark--text">What type of trips do you like ?</span>
        <v-row class="mt-2" no-gutters>
          <v-col class="text-center" cols="12" sm="6" md="3" lg="4" v-for="item in user.tags" :key="item.id">
            <v-checkbox
            hide-details
            :label="item.name"
            v-model="checked"
            readonly
            :value="true"
            class="mx-auto"
            >
              <template v-slot:label>
                {{item.name}}
                <v-rating
                background-color="primary lighten-1"
                color="primary"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                length="5"
                readonly
                size="15"
                dense
                :value="item.rank ? item.rank : 1"
                ></v-rating>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <div class="text-h5 primaryDark--text mt-10 mb-3">What type of travelers are you? What you like to do on your vacation ?</div>
        <v-expansion-panels v-model="panel" multiple class="" v-if="user.interest_questions.length" focusable>
          <v-expansion-panel class="my-2" v-for="item in user.interest_questions" :key="item.id">
            <v-expansion-panel-header class="body-1">{{item.interest_question}}</v-expansion-panel-header>
            <v-expansion-panel-content class="py-5">
              <v-row justify="space-between">
                <p class="ml-3">{{ item.pivot.answer }}</p>
                <v-rating
                background-color="primary lighten-1"
                color="primary"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                length="5"
                readonly
                size="15"
                dense
                :value="item.pivot.rank ? item.pivot.rank : 1"
                ></v-rating>
                <!-- <v-btn @click="deleteInterest(item.id)" icon color="red"><v-icon>mdi-trash-can</v-icon></v-btn> -->
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-model="panel" multiple class="mt-10" v-else focusable>
        <v-expansion-panel class="my-2" v-for="item in user.allQuestions" :key="item.id">
          <v-expansion-panel-header class="body-1">{{item.question}}</v-expansion-panel-header>
          <v-expansion-panel-content class="py-5">
            <v-row justify="space-between">
              <p class="ml-3">Take the survey to answer this question</p>
              <v-btn @click="surveyStarted = true; step = 2" icon color="red"><v-icon>mdi-eye</v-icon></v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>

    <div v-else>
      <v-img :width="surveyStarted ? '20%' : '50%'" contain class="mx-auto" src="../../assets/survey.png"></v-img>
      <v-btn v-if="!surveyStarted" class="d-block mx-auto mt-10" elevation="0" x-large color="primary" @click="surveyStarted = true">Start Survey</v-btn>

      <!-- survey stepper -->
      <div v-if="surveyStarted">
        <h2 class="primary--text text-center text-capitalize">start fill your interests</h2>
        <v-stepper v-model="step" alt-labels color="primary" elevation="0">
          <v-stepper-header style="width: 70%; box-shadow: none;" class="mx-auto" flat>
            <v-stepper-step
              class="px-0"
              editable
              step="1"
            >
              Choose your interests
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class="px-0"
              step="2"
              editable
            >
              Answer some questions
            </v-stepper-step>

          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="item in allTags" :key="item.id">
                  <v-card
                    class="mb-1 ma-5 pb-3"
                    width="170"
                  >
                    <v-card-title class="d-flex pa-0 justify-end">
                      <v-checkbox
                      v-model="selectedTags"
                      :readonly="item.type !== 'direct'"
                      :value="item"
                      hide-details
                      multiple
                      @change="deselectTag(item)"
                      ></v-checkbox>
                    </v-card-title>
                    <v-card-text class="text-center font-weight-bold pb-2">{{item.name}}</v-card-text>
                    <v-rating
                    background-color="primary lighten-1"
                    color="primary"
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    length="5"
                    size="25"
                    hover
                    :readonly="!selectedTags.find(el => el.id === item.id)"
                    dense
                    v-model="item.rank"
                    class="text-center"
                    ></v-rating>
                  </v-card>
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                class="mx-auto mt-5 px-10 d-block"
                large
                elevation="0"
                @click="selectTags"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div v-if="allQuestions.length">
                <div
                class="pa-2"
                v-for="(item) in allQuestions"
                :key="item.id"
                >
                  <v-card class="my-2 pa-3">
                    <p class="text-h5 mb-5">{{ item.question }}</p>
                    <div v-if="user.interest_questions.find(el => el.id === item.id)" class="d-flex justify-space-between">
                      <p>{{user.interest_questions.find(el => el.id === item.id).pivot.answer}}</p>
                      <v-rating
                      background-color="primary lighten-1"
                      color="primary"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      length="5"
                      size="25"
                      hover
                      dense
                      v-model="user.interest_questions.find(el => el.id === item.id).pivot.rank"
                      @input="updateAnswerRank({
                        answer: user.interest_questions.find(el => el.id === item.id).pivot.answer,
                        id: user.interest_questions.find(el => el.id === item.id).pivot.answer_id,
                        question_id: item.id,
                        rank: user.interest_questions.find(el => el.id === item.id).pivot.rank
                      })"
                      class="text-center"
                      ></v-rating>
                      <!-- <v-btn icon color="primary" @click="editQuestion = item.id"><v-icon>mdi-pencil</v-icon></v-btn> -->
                    </div>
                    <div>
                      <v-select
                      v-model="selectedValue"
                      label="Select an option"
                      outlined
                      return-object
                      item-text="Answer"
                      item-value="id"
                      full-width
                      :items="item.answers">
                      </v-select>
                      <v-card-actions>
                        <v-btn
                        @click="selectedValue = null; editQuestion = 0"
                        class="ml-2 mt-4" color="primary" text>
                        Discard Answer
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn class="mt-4"
                        @click="user.interest_questions.find(el => el.id === item.id) ? editInterest(item.id) : addInterest(item.id)"
                        color="primary" elevation="0" :loading="dropBtnLoad">
                        Submit answer
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </div>
              </div>
              <p v-else class="text-center my-10 primary--text">No more questions to answer! You're all Done.</p>

              <div class="text-center">
                <v-btn
                  color="white primary--text"
                  class="px-10 mb-3 mx-2"
                  large
                  elevation="2"
                  @click="step = '1'"
                >Previous</v-btn>
                <v-btn
                  color="primary"
                  class="px-10 mb-3"
                  large
                  elevation="0"
                  @click="surveyStarted = false; step = '1'"
                >Countinue</v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      checked: true,
      steps: '2',
      step: '1',
      panel: [],
      surveyStarted: false,
      userInterests: [],
      deleteBtnLoad: false,
      allQuestions: [],
      directTags: [],
      editingSports: 0,
      textBtnLoad: false,
      dropBtnLoad: false,
      yesNoBtnLoad: false,
      editingPlayerName: 0,
      editQuestion: 0,
      radio: null,
      baseRadio: null,
      selectedValue: null,
      baseSelectedValue: null,
      currentAnswer: null,
      baseAnswer: null,
      items: [
        {
          value: 1,
          name: 'haha1'
        },
        {
          value: 2,
          name: 'haha2'
        }
      ],
      tagIds: [],
      selectedTags: []
    }
  },
  computed: {
    ...mapState(['user']),
    allTags () {
      return [...this.user.tags, ...this.directTags]
    }
  },
  methods: {
    useAnswer (id) {
      const interest = this.userInterests.find(x => x.question_id === id)
      if (interest) {
        const question = this.allQuestions.find(x => x.id === interest.question_id)
        if (question.type === 'yesorno' && interest.answer === 'Yes') {
          this.radio = 0
          this.baseRadio = 0
        } else if (question.type === 'yesorno' && interest.answer === 'No') {
          this.radio = 1
          this.baseRadio = 1
        } else if (question.type === 'dropdown') {
          const answers = this.allQuestions.find(x => x.id === id).answers
          if (answers) {
            this.selectedValue = answers.find(x => x.Answer === interest.answer)
            this.baseSelectedValue = answers.find(x => x.Answer === interest.answer)
          }
        } else if (question.type === 'essay') {
          this.currentAnswer = interest.answer
          this.baseAnswer = interest.answer
        }
      }
    },
    deleteInterest (id) {
      this.deleteBtnLoad = true
      user.deleteInterest(id).then(res => {
        this.user.interest_questions = res.data.data
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      }).finally(() => {
        this.deleteBtnLoad = false
      })
    },
    updateAnswerRank (payload) {
      user.editInterest(payload, payload.question_id).then(res => {
        this.user.interest_questions = res.data.data
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      }).finally(() => {
        this.editQuestion = 0
        this.dropBtnLoad = false
      })
    },
    editInterest (id) {
      console.log('here', this.selectedValue)
      const question = this.allQuestions.find(x => x.id === id)
      if (question) {
        // if (this.selectedValue !== this.baseSelectedValue) {
        this.dropBtnLoad = true
        const data = {
          answer: this.selectedValue.Answer,
          id: this.selectedValue.id,
          rank: 1
        }
        user.editInterest(data, id).then(res => {
          // this.user.interest_questions[this.user.interest_questions.indexOf(question)] = res.data.data
          // this.getUser()
          this.user.interest_questions = res.data.data
          // this.user.interest_questions.forEach(x => {
          //   if (x.id === id) {
          //     x.pivot.answer = res.data.data.pivot.answer
          //   }
          // })
        }).catch(() => {
          this.$emit('error', 'Something went wrong')
        }).finally(() => {
          this.editQuestion = 0
          this.dropBtnLoad = false
        })
        // } else {
        //   this.editQuestion = 0
        //   this.dropBtnLoad = false
        // }
      }
    },
    addInterest (id) {
      const question = this.allQuestions.find(x => x.id === id)
      if (question) {
        const data = { answer: this.selectedValue.Answer, id: this.selectedValue.id, rank: 1 }
        user.addInterest(data, question.id).then(res => {
          this.user.interest_questions = res.data.data
        }).catch(() => {
          this.$emit('error', 'Something went wrong')
        }).finally(() => {
          this.editQuestion = 0
          this.selectedValue = 0
        })
      }
    },
    selectTags () {
      if (this.selectedTags.length) {
        this.tags = this.selectedTags
        user.assignTags({ tags: this.tags }).then(res => {
          // this.user.tags = res.data.data
          this.getUser()
          this.step = 2
          this.getDirectTags()
        }).catch(() => {
          this.$emit('error', 'Something went wrong')
        })
      } else {
        this.step = 2
      }
    },
    deselectTag (tag) {
      console.log('in', this.selectedTags)
      if (tag) {
        if (this.selectedTags.find(el => el.id === tag.id) && !tag.rank) {
          tag.rank = 1
        } else if (this.user.tags.find(el => el.id === tag.id)) {
          user.removeTags({ tagIds: [tag.id] }).then(res => {
            tag.rank = 0
            // this.user.tags = res.data.data
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          })
        } else {
          tag.rank = 0
        }
      }
    },
    getUser () {
      user.profile().then(res => {
        this.$store.dispatch('setUserData', res.data.data)
        this.selectedTags = res.data.data.tags
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      })
    },
    getDirectTags () {
      user.directTags().then(res => {
        this.directTags = res.data.data
      }).catch(error => {
        this.$emit('error', error)
      })
    }
  },
  created () {
    this.getDirectTags()
    this.selectedTags = this.user.tags
    user.interestQuestions().then(res => {
      this.allQuestions = res.data.data
      this.allQuestions.forEach((x, i) => {
        this.panel.push(i)
      })
    }).catch(error => {
      this.$emit('error', error)
    })
  }
}
</script>
